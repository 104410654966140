body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    background: url('images/background.jpg') no-repeat center fixed;
    background-size: cover;
}

@page {
    margin: 0;
}
@media print {
    footer {
        display: none;
        position: fixed;
        bottom: 0;
    }
    header {
        display: none;
        position: fixed;
        top: 0;
    }
}
