.right-login {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-width: 420px;
    background-color: rgb(255, 255, 255);
}

.login {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    z-index: 2;
    width: 420px;
    background: transparent;
    box-shadow: rgb(0 0 0 / 75%) 0px 0px 35px -5px;
    margin-left: 5%;
}

.login-logo {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 50px auto 100px 50px;
}

.logo-title {
    display: flex;
    flex-direction: column;
}

.header {
    font-weight: 700;
    font-size: 20px;
    display: inline-block;
    font-family: Lato, sans-serif;
    line-height: 1.6;
}

.subheader {
    font-weight: 400;
    font-size: 12px;
    display: inline-block;
    font-family: Lato, sans-serif;
    line-height: 1.6;
}

.title-spinner {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    align-items: center;
}

.copyright {
    position: absolute;
    bottom: 10px;
}

.copyright_items {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
